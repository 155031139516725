import { GetStaticProps, InferGetStaticPropsType, NextPage, GetStaticPaths } from 'next'
import { Text, Flex, Box, Button } from 'components/primitives'
import Layout from 'components/Layout'
import Script from 'next/script'
import { styled } from 'stitches.config';
import { Luckiest_Guy } from '@next/font/google'
import { Inter } from '@next/font/google'
import { ComponentPropsWithoutRef, useContext, useState } from 'react'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import backgroundImage from 'public/backgroundSparkle.png'
import { Footer } from 'components/home/Footer'
import { useMediaQuery } from 'react-responsive'
import { useMarketplaceChain, useMounted } from 'hooks'
import { Card } from 'components/Card'
import { useAccount } from 'wagmi'
import { paths } from '@reservoir0x/reservoir-sdk'
import { useCollections } from '@reservoir0x/reservoir-kit-ui'
import fetcher from 'utils/fetcher'
import { NORMALIZE_ROYALTIES } from '../_app'
import supportedChains from 'utils/chains'
import Link from 'next/link'
import ChainToggle from 'components/common/ChainToggle'
import CollectionsTimeDropdown, {
  CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import { Head } from 'components/Head'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { ChainContext } from 'context/ChainContextProvider'

type Props = InferGetStaticPropsType<typeof getStaticProps>

const luckiest_guy = Luckiest_Guy({
  subsets: ['latin'],
  weight: "400",
})

const IndexPage: NextPage<Props> = ({ ssr }) => {
  const isSSR = typeof window === 'undefined'
  const { routePrefix, proxyApi } = useMarketplaceChain()
  const isMounted = useMounted()
  const compactToggleNames = useMediaQuery({ query: '(max-width: 800px)' })
  const [sortByTime, setSortByTime] =
    useState<CollectionsSortingOption>('1DayVolume')
  const marketplaceChain = useMarketplaceChain()
  const { isDisconnected } = useAccount()
  const card1Images = ["https://dl.openseauserdata.com/cache/originImage/files/76e5fbae85245ef75efc0f23d302cc35.png", "https://dl.openseauserdata.com/cache/originImage/files/c87e2dac2803c797f441b5bdd235fde0.png", "https://dl.openseauserdata.com/cache/originImage/files/5426e71cfe126de6d66aed9cf74a3b4c.png", "https://dl.openseauserdata.com/cache/originImage/files/a261544848cadd8957aa767e68d3aff7.png", "https://dl.openseauserdata.com/cache/originImage/files/2261c4e05d3ffca7214fc46948f9b6ad.png"];
  const card2Images = ["https://dl.openseauserdata.com/cache/originImage/files/5cdf3c9bead6e3410a385a20568ac877.png", "https://dl.openseauserdata.com/cache/originImage/files/33eb53042e74f4b248283a4b59dbb2ea.png", "https://dl.openseauserdata.com/cache/originImage/files/b7d4526b85caa1382da13c752f300aab.png", "https://dl.openseauserdata.com/cache/originImage/files/9e89b6c758ed01b9c20f60435ff6c0f1.png", "https://dl.openseauserdata.com/cache/originImage/files/e9b3b3b33163f2ec90daeb73d1ef748a.png"];
  const card3Images = ["https://dl.openseauserdata.com/cache/originImage/files/2243ff14a7f006401f49f27128e71496.jpg", "https://dl.openseauserdata.com/cache/originImage/files/86fb16b0a7f3abc550dd460211c86be6.png", "https://dl.openseauserdata.com/cache/originImage/files/0745252729240748b1da5d00a250f024.png", "https://dl.openseauserdata.com/cache/originImage/files/b3abc1636288de97eba0e3a434fd6bb9.png", "https://dl.openseauserdata.com/cache/originImage/files/966c33d15495bb2b912bce2382996c8b.png"];
  const card4Images = ["https://dl.openseauserdata.com/cache/originImage/files/ae0582399ed49f7e8f59c6e80bf13cda.jpg", "https://dl.openseauserdata.com/cache/originImage/files/27c94f10f0de590dec0276aba1f501c7.jpg", "https://dl.openseauserdata.com/cache/originImage/files/9476c1293c5f22d99a3b63454f7efe39.jpg", "https://dl.openseauserdata.com/cache/originImage/files/2f6deae31c1a5798219454d9f4caa447.jpg", "https://dl.openseauserdata.com/cache/originImage/files/d8044aa2ae2f9fd1968e7dbd6e22ea73.jpg"];
  const card5Images = ["https://dl.openseauserdata.com/cache/originImage/files/98b97d48e55c6bb0e32eb9d6213314a3.gif", "https://dl.openseauserdata.com/cache/originImage/files/92ae4a98f82c2248030fdc34ce286fec.gif", "https://dl.openseauserdata.com/cache/originImage/files/68c424dcf22d14d2b102442eef52ab62.gif", "https://dl.openseauserdata.com/cache/originImage/files/6102ed00b0c04114c62ede9a2353e0be.gif", "https://dl.openseauserdata.com/cache/originImage/files/b34dc07acfc9f8f7e36341a3547db34d.gif"];
  const card6Images = ["https://dl.openseauserdata.com/cache/originImage/files/4e157de540a07a849001cfdba9d46fd1.gif", "https://dl.openseauserdata.com/cache/originImage/files/779c15f5b16dd73626d994ed034cee69.png", "https://dl.openseauserdata.com/cache/originImage/files/eff2a1434450d229c490b46a2b22ca4f.png", "https://dl.openseauserdata.com/cache/originImage/files/e3c37ec94fc230cf070e5d31bed38fc6.gif", "https://dl.openseauserdata.com/cache/originImage/files/a6ced85ccc25d4bb2bf2303f9b4c77b0.png"];


  const { chain } = useContext(ChainContext)


  let volumeKey: ComponentPropsWithoutRef<
    typeof CollectionRankingsTable
  >['volumeKey'] = 'allTime'

  switch (sortByTime) {
    case '1DayVolume':
      volumeKey = '1day'
      break
    case '7DayVolume':
      volumeKey = '7day'
      break
    case '30DayVolume':
      volumeKey = '30day'
      break
  }

  return (
    <Layout>
      <Head />
      <Box
  css={{
    background: '$hunnysPurple',
    p: 24,
    height: '100%',
    width: '100%',
    position: 'relative', // make sure it's relative
    '@bp800': {
      p: '$6',
    },
    '&::before': backgroundImage
  }}
>

      <Flex
        direction="column"
        align="center"
        css={{ mx: 'auto', maxWidth: '100%', pt: '$5', textAlign: 'center' }}
      >
      <Text css={{
          fontFamily: "Luckiest Guy, cursive",
          fontSize: "4rem",
          fontWeight: 100,
          letterSpacing: "1px",
          textTransform: "uppercase",
        }}>
          The Hunnys Marketplace
        </Text>
        <Text css={{
            fontFamily: "__Inter_9d0077",
            fontSize: "2rem",
            letterSpacing: "1px",
          }}>
            Unleash Your Creativity
          </Text>
        </Flex>
        {isDisconnected && (
          <Flex
            direction="column"
            align="center"
            css={{ mx: 'auto', maxWidth: '100%', pt: '$5', textAlign: 'center' }}
          >
            <Text as="h2" css={{ mb: 48 }}>
              Connect your wallet to begin shopping, or to explore what you already own!
            </Text>
              <ConnectWalletButton />
          </Flex>
        )}
        <Flex css={{ my: '$6', gap: 65, maxWidth: '100%', justifyContent: 'end' }} direction="column">
          <Flex
            justify="end"
            align="stretch"
            css={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 24,
              '@bp1300': {
                justifyContent: 'space-between',
              },
              '@bp800': {
                justifyContent: 'space-between',
              },
              '@bp500': {
                justifyContent: 'center',
              },
            }}
            >
            <Card
              headline="Hunnys"
              description="Unleash your creativity - 10,000 unique, colorful NFTs on Ethereum. Witness the revolutionary color harmony system for yourself that makes each & every Hunny so visually satisfying, and fun to collect. The best place to start to explore The Bunny Kingdom."
              carouselImages={card1Images}
              button2Link="/about/hunnys"
              button2Text="INFO"
              button1Link="/collection/ethereum/0x5dfeb75abae11b138a16583e03a2be17740eaded"
              button1Text="SHOP"
            />
            <Card
              headline="Hunnys Seasons"
              description="Hunnys Seasons are rewards delivered monthly to Hunnys holders! Every month, a set of 5-7 brand new Seasons NFTs are minted. They introduce new characters & stories within the Hunnys universe. Themes voted on by community, then airdropped for free!"
              carouselImages={card2Images}
              button2Link="about/hunnys-seasons"
              button2Text="INFO"
              button1Link="/collection/polygon/0x43668e306dba9172824524fd2c0c6924e710ea5b"
              button1Text="SHOP"
            />
            <Card
              headline="Hunnys Closet Shop"
              description="Welcome to the Hunnys Closet Shop! This is a collection of pieces that will immediately unlock specific traits for use in the Hunnys Closet, coming September 12! Some unlock single items, some unlock dyes, and some unlock full, matching sets of Closet items!"
              carouselImages={card6Images}
              button2Link="https://closet.hunnys.io"
              button2Text="CLOSET"
              button1Link="/collection/polygon/0x11bc733e719198e2b3746f6841d2818ca87af6da"
              button1Text="SHOP"
            />
            <Card
              headline="Hupe Scouts"
              description="The Official Hunnys Community-Owned Derivative Brand. This collection is grown, governed, & maintained by members of the Hunnys community, primarily within Discord. Plus, all creators receive an exclusive Haley the Hupe Scout NFT!"
              carouselImages={card3Images}
              button2Link="https://hupe.hunnys.io"
              button2Text="MINT"
              button1Link="/collection/ethereum/0x9fa26d53b63e9fa544675b790ae7d5c24b94bc6e"
              button1Text="SHOP"
            />
            <Card
              headline="Hunnys OGs"
              description="Hunnys OGs is an exclusive collection of hand-drawn 1/1 avatars created by the artist CryptoStacys. The collection contains the first 100 hand-drawn Hunnys, which inspired the 10k collection, as well as 50 others by gift, commission, and invitation."
              carouselImages={card4Images}
              button1Link="/collection/ethereum/0x64bd44df5590cfe4f0395b05fa0e6f096734bb77"
              button1Text="SHOP"
            />
            <Card
              headline="Stacys"
              description="The award-winning Stacys collection is a unique and historic example of hand-drawn, 1/1 collectible pixel art NFTs. One of the earliest known women-led NFTs representing women, as well as one of the earliest animated pixel art collections by a female creator."
              carouselImages={card5Images}
              button1Link="/collection/ethereum/0x21ac5f6c96ccb174115b18318e3e340237a55606"
              button1Text="SHOP"
            />
          </Flex>
          <Box css={{ alignSelf: 'center' }}>
            <Link href={`/${routePrefix}/collection-rankings`}>
              <Button
                css={{
                  minWidth: 224,
                  justifyContent: 'center',
                  fontFamily: 'Luckiest Guy, cursive',
                  fontWeight: 'normal',
                  mb: '$2',
                  fontSize: '28px'
                }}
                size="large"
              >
                VIEW All COLLECTIONS
              </Button>
            </Link>
          </Box>
        </Flex>
        <Footer />
      </Box>
    </Layout>
  )
}

type CollectionSchema =
  paths['/collections/v5']['get']['responses']['200']['schema']
type ChainCollections = Record<string, CollectionSchema>

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<{
  ssr: {
    collections: ChainCollections
  }
}> = async () => {
  let collectionQuery: paths['/collections/v5']['get']['parameters']['query'] =
    {
      sortBy: '1DayVolume',
      normalizeRoyalties: NORMALIZE_ROYALTIES,
      includeTopBid: true,
      limit: 10,
    }

  const promises: ReturnType<typeof fetcher>[] = []
  supportedChains.forEach((chain) => {
    const query = { ...collectionQuery }
    if (chain.collectionSetId) {
      query.collectionsSetId = chain.collectionSetId
    } else if (chain.community) {
      query.community = chain.community
    }
    promises.push(
      fetcher(`${chain.reservoirBaseUrl}/collections/v5`, query, {
        headers: {
          'x-api-key': chain.apiKey || '',
        },
      })
    )
  })
  const responses = await Promise.allSettled(promises)
  const collections: ChainCollections = {}
  responses.forEach((response, i) => {
    if (response.status === 'fulfilled') {
      collections[supportedChains[i].id] = response.value.data
    }
  })

  return {
    props: { ssr: { collections } },
    revalidate: 5,
  }
}

export default IndexPage
